import React from 'react';
import MainTitle from '../views/main-title';
import axios from 'axios';
import styled from 'styled-components';
import { theme } from '../../configurations/general-conf';
import CascadeList from '../widgets/cascade-list';
import _ from 'lodash';


const BottomButtonsContainer = styled.div`
  float: right;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const BottomButton = styled.button`
  height: 38px;
  width: 120px;
  border: none;
  padding: 0;
  margin-left: 10px;
  font-size: 12px;
  color: #fff;
  background-color: ${
    props => props.submitChange ? theme.mainLighterColor : props.cancelChange ? theme.failureColor : theme.mainDarkColor
  };

  &:hover {
    background-color: ${
      props => props.submitChange ? theme.mainLightColor : props.cancelChange ? theme.failureLightColor : theme.mainDarkColorHover
    }
  }
`;

export default class extends React.Component {

  async componentDidMount () {
    const { endPoint, name } = this.props.conf;
    const { listItemId } = this.props.match.params;
    const endPointGet = endPoint.get ? endPoint.get.replace(':uuid', listItemId) : endPoint.replace(':uuid', listItemId);
    
    const res = await axios.get(endPointGet);
    if (res.data.objs && name==='dispatch') {
      this.refs[name].setState({ dataList: res.data.objs });
    };
  }

  cancelChange = () => {
    const { route } = this.props.conf;
    const backPath = this.props.match.path.replace(route + '/:listItemId', '');
    this.props.history.push(backPath);
  }

  saveChange = async () => {
    const { endPoint, name, conf, route } = this.props.conf;
    const { listItemId } = this.props.match.params;
    if (name==='dispatch') {
      const { dataToSubmit, dataList } = this.refs[name].state;
      const notEqual = _.filter(dataList, item => item.subTotal !== item[conf.subTotalMatchKey]);
      if(notEqual.length===0){
        const endPointPut = endPoint.put ? endPoint.put.replace(':uuid', listItemId) : endPoint.replace(':uuid', listItemId);
        // console.log(dataToSubmit);
        this.refs["saveChangeButton"].disabled = true;
        const res = await axios.put(endPointPut, dataToSubmit);
        if (res.data.result === 1) {
          const backPath = this.props.match.path.replace(route + '/:listItemId', '');
          this.props.history.push(backPath);
        } else {
          this.refs["saveChangeButton"].disabled = false;
          window.alert(res.data.msg);
        }
      }
    }
  }

  render () {
    const { title, name, conf } = this.props.conf;
    return (
      <React.Fragment>
        <MainTitle title={title} />

        { name==='dispatch' ? <CascadeList ref={name} conf={conf} /> : null }

        <BottomButtonsContainer>
          <BottomButton submitChange ref={"saveChangeButton"} onClick={this.saveChange}> Submit </BottomButton>
          <BottomButton cancelChange onClick={this.cancelChange}> Cancel </BottomButton>
        </BottomButtonsContainer>
      </React.Fragment>
    );
  }
}