import React from 'react';
import styled from 'styled-components';
import ListHeader from './list-header';
import ListBody from './list-body';

const DataListContainer = styled.div`
  padding-top: 10px;
`;

export default (props) => {
  // console.log(props);
  return (
    <DataListContainer>
      <ListHeader cfgs={props.cfgs} showCheckbox={props.showCheckbox} buttonAreaWidth={props.buttonAreaWidth} />
      <ListBody {...props} />
    </DataListContainer>
  );
}