import { createStore } from 'redux';
import createReducer from './reducers';
import _ from 'lodash';

function configureStore() {
  const store = createStore(createReducer());
  store.asyncReducers = {};
  return store;
}

const store = configureStore();
export default store;

export function addAsyncReducer(store, conf) {
  const initialState = {};
  initialState.list = [];
  initialState.selection = [];
  initialState.extensionSelection = [];
  initialState.totalRecords = 0;
  initialState.pageSize = conf.pageSize ? conf.pageSize : 10;
  initialState.currentPage = 1;
  const filters = conf.filters ? conf.filters.slice() : [];
  initialState.filters = filters.map( filter => {
    filter['currentValue'] = null;
    return filter;
  });
  initialState.endPoint = conf.endPoint;
  initialState.orderBy = conf.orderBy;
  if(conf.subListBinder){
    initialState[conf.subListBinder.name] = conf.subListBinder.defaultValue;
  }

  const totalWidth = _.sumBy(conf.tableCfg, (o) => o.widthRatio);
  conf.tableCfg && conf.tableCfg.slice().map( item => {
    if(item.title){
      item.widthRatio = item.widthRatio / totalWidth;
    }
    return item;
  });

  const reducer = function(state = initialState, action) {

    switch(action.type) {
      case 'get_' + conf.nameSpace:
        return Object.assign({}, state, { list: action.data.objs, totalRecords: action.data.total });
      case 'del_single_' + conf.nameSpace:
        var newList = [];
        if(conf.nameSpace === 'sampleOrder') {
          newList = _.filter(state.list, item => item['saleOrderUuid'] !== action.listItemId);
        } else if (conf.nameSpace === 'promotion' || conf.nameSpace === 'masspromotion') {
          newList = _.filter(state.list, item => item['couponCode'] !== action.listItemId);
        } else {
          newList = _.filter(state.list, item => item[conf.nameSpace + 'Uuid'] !== action.listItemId);
        }
        const newTotal = state.totalRecords - 1;
        return Object.assign({}, state, { list: newList, totalRecords: newTotal });
      case 'add_' + conf.nameSpace + '_to_selection':
        const newSelection = state.selection.concat([action.listItemId]);
        return Object.assign({}, state, { selection: newSelection});
      case 'remove_' + conf.nameSpace + '_from_selection':
        const newSelectionR = _.filter(state.selection, itemId => itemId !== action.listItemId);
        return Object.assign({}, state, { selection: newSelectionR});
      case 'add_' + conf.nameSpace + '_extension':
        const newExtension = state.extensionSelection.concat([action.listItemId]);
        return Object.assign({}, state, { extensionSelection: newExtension});
      case 'remove_' + conf.nameSpace + '_extension':
          const newExtensionR = _.filter(state.extensionSelection, itemId => itemId !== action.listItemId);
          return Object.assign({}, state, { extensionSelection: newExtensionR});
      default:
    }
  
    return state;
  
  }

  store.asyncReducers[conf.nameSpace] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
}
