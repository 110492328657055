import React from 'react';
import { theme } from './general-conf';

// Using "Stateless Functional Components"
export default function(props) {
  return (
    <svg style={{display: 'none'}} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px">
			<defs>
    			{/*<symbol id="editpen" viewBox="0 0 15.989 15.983">*/}
    			<symbol id="editpen" viewBox="0 0 100 100" fill="none" stroke="#00AEEF" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
    			<title>Edit Pen</title>
					{/*<polygon fill={theme.mainEditColor} points="6.525,13.331 6.005,12.812 13.44,5.377 10.612,2.549 3.177,9.984 2.658,9.465 10.612,1.51 14.479,5.377 "/>
					<g>
						<polygon fill={theme.mainEditColor} points="1.975,10.417 2.393,10.836 0.877,15.109 5.161,13.603 5.58,14.022 0,15.983 	"/>
					</g>
					<path fill={theme.mainEditColor} d="M15.19,4.666l-0.52-0.52c0.78-0.78,0.78-2.048,0-2.828c-0.779-0.779-2.048-0.78-2.827,0l-0.52-0.52
						c1.066-1.066,2.801-1.066,3.867,0C16.256,1.866,16.256,3.6,15.19,4.666z"/>*/}
					<g>
						<polygon points="95.4,29.8 45.9,79.3 21.7,86.8 29.3,62.7 78.8,13.2 "/>
						<line x1="29.3" y1="62.7" x2="45.9" y2="79.3"/>
						<line x1="4.6" y1="86.8" x2="83" y2="86.8"/>
					</g>
				</symbol>

				<symbol id="deletebin" viewBox="0 0 12.061 16.768">
    			<title>Delete Bin</title>
					<path fill="#DC512D" d="M11.845,16.768H0.217V4.266h11.628V16.768z M0.952,16.033H11.11V5.001H0.952V16.033z"/>
					<g>
						<rect x="2.753" y="6.847" fill="#DC512D" width="0.735" height="7.423"/>
						<rect x="5.663" y="6.847" fill="#DC512D" width="0.735" height="7.423"/>
						<rect x="8.573" y="6.847" fill="#DC512D" width="0.735" height="7.423"/>
					</g>
					<rect x="0" y="1.726" fill="#DC512D" width="12.061" height="0.735"/>
					<rect x="4.727" y="0" fill="#DC512D" width="2.607" height="0.735"/>
				</symbol>

				<symbol id="download" viewBox="0 0 24 24" fill="none" stroke={theme.mainDarkColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    			<title>Download</title>
					<path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
					<polyline points="7 10 12 15 17 10"></polyline>
					<line x1="12" y1="15" x2="12" y2="3"></line>
				</symbol>

				<symbol id='approve' viewBox="0 0 48 48">
				<title>Approve</title>
					<path fill="#c8e6c9" d="M36,42H12c-3.314,0-6-2.686-6-6V12c0-3.314,2.686-6,6-6h24c3.314,0,6,2.686,6,6v24C42,39.314,39.314,42,36,42z"/>
					<path fill="#4caf50" d="M34.585 14.586L21.014 28.172 15.413 22.584 12.587 25.416 21.019 33.828 37.415 17.414z"/>
				</symbol>

				<symbol id="docket" viewBox="0 0 100 100" fill="none" stroke="#00AEEF" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
    			<title>Docket</title>
					<g>
						<rect x="50.7" y="20.3" transform="matrix(0.7071 0.7071 -0.7071 0.7071 46.8797 -37.2281)" className="st0" width="35.4" height="35.4"/>
						<polyline className="st0" points="93.4,38 93.4,62 68.4,87 43.4,62 43.4,38 "/>
						<polyline className="st0" points="55.6,25.7 80.6,50.7 80.6,62 "/>
						<line className="st0" x1="68.4" y1="62.5" x2="68.4" y2="87"/>
						<polyline className="st0" points="55.6,74.8 55.6,80.6 6.6,80.6 6.6,13.4 55.6,13.4 55.6,25.7 "/>
						<polyline className="st0" points="14.4,29.3 17.9,34 24.4,22.7 "/>
						<line className="st0" x1="31.1" y1="29.7" x2="46.8" y2="29.7"/>
						<polyline className="st0" points="14.4,48.9 17.9,53.6 24.4,42.3 "/>
						<line className="st0" x1="31.1" y1="49.3" x2="43.4" y2="49.3"/>
						<polyline className="st0" points="14.4,68.6 17.9,73.3 24.4,62 "/>
						<line className="st0" x1="31.1" y1="69" x2="46.8" y2="69"/>
					</g>
				</symbol>

				<symbol id="packingslip" viewBox="0 0 100 100" fill="none" stroke="#F7941D" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
    			<title>Packing Slip</title>
					<g>
						<g>
							<circle className="st0" cx="16.4" cy="79.8" r="9.6"/>
							<circle className="st0" cx="63.8" cy="79.8" r="9.6"/>
							<line className="st0" x1="26" y1="79.8" x2="54.2" y2="79.8"/>
							<polyline className="st0" points="63.8,70.2 63.8,51.6 58,51.6 55.6,63.4 42.4,63.4 35.2,51.6 13.5,51.6 13.5,70.7 "/>
							<polyline className="st0" points="60.7,51.6 51.6,19.7 22.6,19.7 22.6,51.6 "/>
							<polyline className="st0" points="71,12.7 71,73.5 93.1,73.5 "/>
						</g>
						<rect x="77.6" y="48.5" className="st0" width="13.5" height="25"/>
						<rect x="71" y="23.1" className="st0" width="16.1" height="25.4"/>
					</g>
				</symbol>
				<symbol id="picklist" viewBox="0 0 100 100" fill="none" stroke="#231F20" strokeWidth="4.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
				<title>Warehouse Picking List </title>
					<g>
						<g>
							<path className="st0" d="M20.9,45.8h1.6l0.9,6.2h0l1-6.2h1.6l1,6.2h0l0.9-6.2h1.6l-1.5,8.4h-1.9l-0.9-6.1h0l-0.9,6.1h-1.9L20.9,45.8z
								"/>
							<path className="st0" d="M31.7,45.8h2.1l2.2,8.4h-1.8l-0.4-1.8h-2.2l-0.4,1.8h-1.8L31.7,45.8z M31.9,51.1h1.6l-0.8-3.8h0L31.9,51.1z"
								/>
							<path className="st0" d="M36.6,45.8h3.1c1.4,0,2.2,0.7,2.2,2.2c0,1.1-0.4,1.9-1.4,2.1v0c1.1,0.2,1.3,0.8,1.3,2.6
								c0,0.9,0.1,1.3,0.4,1.5v0.1h-1.8c-0.2-0.2-0.2-0.6-0.2-0.9l0-1.6c0-0.7-0.3-1.1-1-1.1h-0.9v3.5h-1.7V45.8z M38.3,49.5H39
								c0.8,0,1.3-0.4,1.3-1.3c0-0.8-0.4-1.2-1.2-1.2h-0.8V49.5z"/>
							<path className="st0" d="M47.8,45.8v1.4h-3v2h2.8v1.4h-2.8v2.2h3.1v1.4h-4.8v-8.4H47.8z"/>
							<path className="st0" d="M48.7,45.8h1.7V49h2v-3.2h1.7v8.4h-1.7v-3.7h-2v3.7h-1.7V45.8z"/>
							<path className="st0" d="M57.9,45.7c2.5,0,2.8,1.8,2.8,4.3s-0.3,4.3-2.8,4.3s-2.8-1.8-2.8-4.3S55.4,45.7,57.9,45.7z M57.9,53.1
								c1,0,1.2-0.9,1.2-3.1c0-2.2-0.2-3.1-1.2-3.1s-1.2,0.9-1.2,3.1C56.8,52.3,56.9,53.1,57.9,53.1z"/>
							<path className="st0" d="M63.3,45.8v5.8c0,0.8,0.2,1.5,1,1.5s1-0.6,1-1.5v-5.8H67v5.8c0,2.1-1.3,2.7-2.7,2.7s-2.7-0.5-2.7-2.7v-5.8
								H63.3z"/>
							<path className="st0" d="M69.4,51.6v0.2c0,0.8,0.2,1.3,1.1,1.3c0.5,0,1-0.3,1-1c0-0.8-0.4-1-1.5-1.4c-1.5-0.5-2.2-1.1-2.2-2.5
								c0-1.6,1-2.5,2.6-2.5c1.5,0,2.6,0.6,2.6,2.2v0.2h-1.6c0-0.8-0.3-1.2-0.9-1.2c-0.8,0-1,0.5-1,1c0,0.5,0.2,0.8,0.9,1.1l1.3,0.6
								c1.3,0.6,1.6,1.2,1.6,2.2c0,1.8-1.1,2.6-2.9,2.6c-1.9,0-2.7-0.9-2.7-2.4v-0.3H69.4z"/>
							<path className="st0" d="M78.8,45.8v1.4h-3v2h2.8v1.4h-2.8v2.2h3.1v1.4h-4.8v-8.4H78.8z"/>
						</g>
						<g>
							<polyline className="st1" points="15.6,42.9 15.6,91.5 84.4,91.5 84.4,42.9 "/>
							<polyline className="st1" points="92.7,51.1 50,8.5 7.3,51.1 "/>
							<rect x="22.7" y="60.2" className="st1" width="54.7" height="31.4"/>
						</g>
					</g>
				</symbol>
				<symbol id="summary" viewBox="0 0 115.35 122.88" fill="#000000" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    			<title>Order Summary</title>
					<path d="M25.27,86.92c-1.81,0-3.26-1.46-3.26-3.26s1.47-3.26,3.26-3.26h21.49c1.81,0,3.26,1.46,3.26,3.26s-1.46,3.26-3.26,3.26 H25.27L25.27,86.92L25.27,86.92z M61.1,77.47c-0.96,0-1.78-0.82-1.78-1.82c0-0.96,0.82-1.78,1.78-1.78h4.65c0.04,0,0.14,0,0.18,0 c1.64,0.04,3.1,0.36,4.33,1.14c1.37,0.87,2.37,2.19,2.92,4.15c0,0.04,0,0.09,0.05,0.14l0.46,1.82h39.89c1,0,1.78,0.82,1.78,1.78 c0,0.18-0.05,0.36-0.09,0.55l-4.65,18.74c-0.18,0.82-0.91,1.37-1.73,1.37l0,0l-29.18,0c0.64,2.37,1.28,3.65,2.14,4.24 c1.05,0.68,2.87,0.73,5.93,0.68h0.04l0,0h20.61c1,0,1.78,0.82,1.78,1.78c0,1-0.82,1.78-1.78,1.78H87.81l0,0 c-3.79,0.04-6.11-0.05-7.98-1.28c-1.92-1.28-2.92-3.46-3.92-7.43l0,0L69.8,80.2c0-0.05,0-0.05-0.04-0.09 c-0.27-1-0.73-1.69-1.37-2.05c-0.64-0.41-1.5-0.59-2.51-0.59c-0.05,0-0.09,0-0.14,0H61.1L61.1,77.47L61.1,77.47z M103.09,114.13 c2.42,0,4.38,1.96,4.38,4.38s-1.96,4.38-4.38,4.38s-4.38-1.96-4.38-4.38S100.67,114.13,103.09,114.13L103.09,114.13L103.09,114.13z M83.89,114.13c2.42,0,4.38,1.96,4.38,4.38s-1.96,4.38-4.38,4.38c-2.42,0-4.38-1.96-4.38-4.38S81.48,114.13,83.89,114.13 L83.89,114.13L83.89,114.13z M25.27,33.58c-1.81,0-3.26-1.47-3.26-3.26c0-1.8,1.47-3.26,3.26-3.26h50.52 c1.81,0,3.26,1.46,3.26,3.26c0,1.8-1.46,3.26-3.26,3.26H25.27L25.27,33.58L25.27,33.58z M7.57,0h85.63c2.09,0,3.99,0.85,5.35,2.21 s2.21,3.26,2.21,5.35v59.98h-6.5V7.59c0-0.29-0.12-0.56-0.31-0.76c-0.2-0.19-0.47-0.31-0.76-0.31l0,0H7.57 c-0.29,0-0.56,0.12-0.76,0.31S6.51,7.3,6.51,7.59v98.67c0,0.29,0.12,0.56,0.31,0.76s0.46,0.31,0.76,0.31h55.05 c0.61,2.39,1.3,4.48,2.23,6.47H7.57c-2.09,0-3.99-0.85-5.35-2.21C0.85,110.24,0,108.34,0,106.25V7.57c0-2.09,0.85-4,2.21-5.36 S5.48,0,7.57,0L7.57,0L7.57,0z M25.27,60.25c-1.81,0-3.26-1.46-3.26-3.26s1.47-3.26,3.26-3.26h50.52c1.81,0,3.26,1.46,3.26,3.26 s-1.46,3.26-3.26,3.26H25.27L25.27,60.25L25.27,60.25z"/>
				</symbol>
				<symbol id="reverse" viewBox="0 0 14.728 14.266">
    			<title>Reverse</title>
					<g>
						<polyline fill="none" stroke="#FF2F00" strokeMiterlimit="10" points="0.347,13.906 7.364,7.133 14.38,13.906 	"/>
						<polyline fill="none" stroke="#FF2F00" strokeMiterlimit="10" points="0.347,0.36 7.364,7.133 14.38,0.36 	"/>
					</g>
				</symbol>
				<symbol id="tipsbubble" viewBox="0 0 32.473 25.411">
    			<title>Tips Bubble</title>
					<g>
						<path fill="none" stroke={theme.mainBrightestColor} strokeWidth="2" strokeMiterlimit="10" d="M30.76,23.924l-2.917-6.917
							c0.677-1.362,1.055-2.853,1.055-4.418C28.899,6.189,22.654,1,14.95,1S1,6.189,1,12.59s6.245,11.59,13.95,11.59
							c3.088,0,5.932-0.844,8.243-2.255L30.76,23.924z"/>
						<g>
							<path fill={theme.mainBrightestColor} d="M13.295,8.218c0.514-0.331,1.146-0.497,1.896-0.497c0.985,0,1.804,0.235,2.455,0.706
								c0.652,0.471,0.978,1.168,0.978,2.092c0,0.567-0.142,1.044-0.424,1.432c-0.166,0.235-0.483,0.536-0.952,0.902l-0.462,0.36
								c-0.253,0.196-0.42,0.425-0.502,0.687c-0.052,0.166-0.081,0.423-0.085,0.772h-1.772c0.026-0.737,0.096-1.246,0.208-1.527
								c0.113-0.281,0.404-0.605,0.874-0.971l0.476-0.373c0.156-0.118,0.282-0.246,0.378-0.386c0.173-0.24,0.26-0.503,0.26-0.791
								c0-0.331-0.097-0.633-0.29-0.906c-0.194-0.272-0.547-0.409-1.06-0.409c-0.505,0-0.862,0.168-1.073,0.503
								c-0.21,0.336-0.316,0.684-0.316,1.046h-1.89C12.046,9.618,12.48,8.737,13.295,8.218z M14.381,15.568h1.955v1.89h-1.955V15.568z"/>
						</g>
					</g>
				</symbol>

				<symbol id="preeditpen" viewBox="0 0 29.4 29.4">
    			<title>Prevew Edit Pen</title>
					<rect x="0" y="0" fill="#70BF44" width="29.4" height="29.4"/>
					<g>
						<polygon fill="#FFFFFF" points="13.393,19.504 12.873,18.984 20.308,11.549 17.481,8.722 10.046,16.157 9.526,15.637 17.481,7.682 
							21.348,11.549 	"/>
						<g>
							<polygon fill="#FFFFFF" points="8.843,16.59 9.262,17.008 7.746,21.281 12.029,19.776 12.448,20.195 6.869,22.156 		"/>
						</g>
						<path fill="#FFFFFF" d="M22.058,10.839l-0.52-0.52c0.78-0.78,0.78-2.048,0-2.828c-0.779-0.779-2.048-0.78-2.827,0l-0.52-0.52
							c1.066-1.066,2.801-1.066,3.867,0C23.124,8.038,23.124,9.773,22.058,10.839z"/>
					</g>
				</symbol>

				<symbol id="predeletebin" viewBox="0 0 29.4 29.4">
    			<title>Prevew Delete Bin</title>
					<rect x="0" y="0" fill="#DC512D" width="29.4" height="29.4"/>
					<g>
						<path fill="#FFFFFF" d="M20.845,23.227H9.217V10.725h11.628V23.227z M9.952,22.492H20.11V11.46H9.952V22.492z"/>
						<g>
							<rect x="11.753" y="13.307" fill="#FFFFFF" width="0.735" height="7.423"/>
							<rect x="14.663" y="13.307" fill="#FFFFFF" width="0.735" height="7.423"/>
							<rect x="17.573" y="13.307" fill="#FFFFFF" width="0.735" height="7.423"/>
						</g>
						<rect x="9" y="8.185" fill="#FFFFFF" width="12.061" height="0.735"/>
						<rect x="13.727" y="6.459" fill="#FFFFFF" width="2.607" height="0.735"/>
					</g>
				</symbol>

				<symbol id="closepre" viewBox="0 0 14.728 14.266">
    			<title>Close Preview</title>
					<g>
						<polyline fill="none" stroke="#002D41" strokeMiterlimit="10" points="0.347,13.906 7.364,7.133 14.38,13.906 	"/>
						<polyline fill="none" stroke="#002D41" strokeMiterlimit="10" points="0.347,0.36 7.364,7.133 14.38,0.36 	"/>
					</g>
				</symbol>

				<symbol id="Home" viewBox="0 0 26.743 26.401">
    			<title>Home</title>
					<g>
						<polyline fill="none" stroke={theme.mainBrightestColor} strokeWidth="2" strokeMiterlimit="10" points="21.293,26.401 21.293,14.665 
							24.574,14.665 13.372,1.54 2.168,14.665 5.45,14.665 5.45,26.401 	"/>
						<line fill="none" stroke={theme.mainBrightestColor} strokeWidth="2" strokeMiterlimit="10" x1="13.371" y1="26.401" x2="13.371" y2="16.934"/>
					</g>
				</symbol>

				<symbol id="Purchase Order" viewBox="0 0 26.411 26.411">
    			<title>Media</title>
					<circle fill="none" stroke={theme.mainBrightestColor} strokeWidth="2.0326" strokeMiterlimit="10" cx="13.205" cy="13.205" r="12.189"/>
					<polygon fill={theme.mainBrightestColor} points="20.608,13.205 13.931,6.528 13.931,12.278 8.181,6.528 8.181,19.883 13.931,14.133 13.931,19.883 
						"/>
				</symbol>

				<symbol id="Inventory" viewBox="0 0 39.761 29.299">
    			<title>Inventory</title>
					<g>
						<polygon fill="none" stroke={theme.mainBrightestColor} strokeWidth="2" strokeMiterlimit="10" points="27.682,16.987 19.881,27.609 
							12.079,16.987 12.079,1 27.682,1 	"/>
						<g>
							<polyline fill="none" stroke={theme.mainBrightestColor} strokeWidth="2" strokeMiterlimit="10" points="30.932,3.654 38.761,3.654 
								38.761,17.375 32.46,25.955 26.904,18.389 		"/>
							<polyline fill="none" stroke={theme.mainBrightestColor} strokeWidth="2" strokeMiterlimit="10" points="8.829,3.654 1,3.654 1,17.375 
								7.301,25.955 12.983,18.218 		"/>
						</g>
					</g>
				</symbol>

				<symbol id="Devices" viewBox="0 0 18.645 29.777">
    			<title>Devices</title>
					<rect x="1" y="1" fill="none" stroke={theme.mainBrightestColor} strokeWidth="2" strokeMiterlimit="10" width="16.645" height="24.861"/>
					<rect x="4.728" y="4.305" fill="none" stroke={theme.mainBrightestColor} strokeWidth="1.8007" strokeMiterlimit="10" width="7.025" height="17.682"/>
					<line fill="none" stroke={theme.mainBrightestColor} strokeWidth="1.8007" strokeMiterlimit="10" x1="14.713" y1="3.364" x2="14.713" y2="7.531"/>
					<line fill="none" stroke={theme.mainBrightestColor} strokeWidth="1.8007" strokeMiterlimit="10" x1="14.713" y1="8.833" x2="14.713" y2="13"/>
					<line fill="none" stroke={theme.mainBrightestColor} strokeWidth="1.8007" strokeMiterlimit="10" x1="14.713" y1="16.385" x2="14.713" y2="18.469"/>
					<g>
						<line fill="none" stroke={theme.mainBrightestColor} strokeWidth="1.8007" strokeMiterlimit="10" x1="2.046" y1="25.861" x2="2.046" y2="29.777"/>
						<line fill="none" stroke={theme.mainBrightestColor} strokeWidth="1.8007" strokeMiterlimit="10" x1="16.546" y1="25.861" x2="16.546" y2="29.777"/>
					</g>
				</symbol>

				<symbol id="Client" viewBox="0 0 20.433 26.31">
    			<title>Users</title>
					<g>
						<circle fill="none" stroke={theme.mainBrightestColor} strokeWidth="2" strokeMiterlimit="10" cx="10.216" cy="6.948" r="5.948"/>
						<path fill="none" stroke={theme.mainBrightestColor} strokeWidth="2" strokeMiterlimit="10" d="M7.371,12.173C3.674,13.372,1,16.844,1,20.941
							v5.368"/>
						<path fill="none" stroke={theme.mainBrightestColor} strokeWidth="2" strokeMiterlimit="10" d="M19.433,26.31v-5.368
							c0-4.098-2.675-7.571-6.374-8.77"/>
					</g>
				</symbol>

				<symbol id="Sales Order" viewBox="0 0 24.456 26.377">
    			<title>Sales Order</title>
					<rect x="0" y="8.516" fill={theme.mainBrightestColor} width="4.884" height="17.861"/>
					<rect x="6.524" y="5.307" fill={theme.mainBrightestColor} width="4.884" height="21.069"/>
					<rect x="13.048" y="0" fill={theme.mainBrightestColor} width="4.884" height="26.377"/>
					<rect x="19.572" y="11.818" fill={theme.mainBrightestColor} width="4.884" height="14.559"/>
				</symbol>
				<symbol id="Transfer" viewBox="0 0 118.04 122.88" fill={theme.mainBrightestColor}>
				<title>Stock Transfer</title>
					<path d="M16.08,59.26A8,8,0,0,1,0,59.26a59,59,0,0,1,97.13-45V8a8,8,0,1,1,16.08,0V33.35a8,8,0,0,1-8,8L80.82,43.62a8,8,0,1,1-1.44-15.95l8-.73A43,43,0,0,0,16.08,59.26Zm22.77,19.6a8,8,0,0,1,1.44,16l-10.08.91A42.95,42.95,0,0,0,102,63.86a8,8,0,0,1,16.08,0A59,59,0,0,1,22.3,110v4.18a8,8,0,0,1-16.08,0V89.14h0a8,8,0,0,1,7.29-8l25.31-2.3Z"/>
				</symbol>
				<symbol id="Return" viewBox="0 0 783 750">
				<title>Return</title>
					<g><path fill={theme.mainBrightestColor} d="M 440.5,14.5 C 441.117,14.6107 441.617,14.944 442,15.5C 442.5,51.4985 442.667,87.4985 442.5,123.5C 547.353,133.476 626.519,183.476 680,273.5C 725.559,360.442 729.226,449.109 691,539.5C 649.554,624.604 583.054,679.437 491.5,704C 459.187,710.738 426.52,713.405 393.5,712C 284.485,712.821 175.485,712.654 66.5,711.5C 66.5,695.5 66.5,679.5 66.5,663.5C 191.167,663.667 315.834,663.5 440.5,663C 536.731,652.724 605.897,604.224 648,517.5C 682.103,433.099 674.436,352.766 625,276.5C 579.946,214.957 519.279,179.957 443,171.5C 442.5,207.165 442.333,242.832 442.5,278.5C 437.536,277.051 432.869,274.885 428.5,272C 357.833,230.333 287.167,188.667 216.5,147C 291.065,102.555 365.732,58.3884 440.5,14.5 Z"/></g>
				</symbol>

				<symbol id="Gotolink" viewBox="0 0 29.4 29.4">
    			<title>Go to a link arrow</title>
					<rect x="0" y="0" fill="#002D41" width="29.4" height="29.4"/>
					<polyline fill="none" stroke="#70BF44" strokeMiterlimit="10" points="12.742,7.683 19.515,14.7 12.742,21.717 "/>
				</symbol>

				<symbol id="Addtolist" viewBox="0 0 17 17">
    			<title>Add to list</title>
					<line fill="none" strokeWidth="5" stroke={theme.mainColor} strokeMiterlimit="10" x1="5.49" y1="7.85" x2="13.06" y2="7.85"/>
			          <polygon fill={theme.mainColor} points="12.45 3.29 12.45 12.42 17.01 7.85 12.45 3.29"/>
			          <polyline fill="none" strokeWidth="1.88" stroke={theme.mainColor} strokeMiterlimit="10" points="9.72 14.71 0.94 14.71 0.94 0.94 9.72 0.94"/>
				</symbol>

				<symbol id="Checkmod" viewBox="0 0 15.415 17.278">
    			<title>Check Modification</title>
					<path fill="#70BF44" d="M14.063,5.656v10.678H0.938V1.223h9.846c-0.008-0.316,0.011-0.632,0.057-0.944H0v17h15V5.797
						C14.668,5.778,14.355,5.731,14.063,5.656z"/>
					<line fill="none" stroke="#70BF44" strokeMiterlimit="10" x1="3.437" y1="5.638" x2="7.5" y2="11.473"/>
					<line fill="none" stroke="#70BF44" strokeMiterlimit="10" x1="7.5" y1="11.473" x2="15" y2="0.278"/>
				</symbol>

				<symbol id="Coilset" viewBox="0 0 12 18">
    			<title>Coil Setup</title>
					<g>
						<rect x="1" y="1" fill="none" stroke={theme.mainLightColor} strokeWidth="1" strokeMiterlimit="10" width="12" height="15.551"/>
						<line fill="none" stroke={theme.mainLightColor} strokeWidth="1.8007" strokeMiterlimit="10" x1="10.886" y1="2.479" x2="10.886" y2="5.085"/>
						<line fill="none" stroke={theme.mainLightColor} strokeWidth="1.8007" strokeMiterlimit="10" x1="10.886" y1="5.9" x2="10.886" y2="8.506"/>
						<line fill="none" stroke={theme.mainLightColor} strokeWidth="1.8007" strokeMiterlimit="10" x1="10.886" y1="10.624" x2="10.886" y2="11.927"/>
						<g>
							<line fill="none" stroke={theme.mainLightColor} strokeWidth="1.8007" strokeMiterlimit="10" x1="1.754" y1="16.551" x2="1.754" y2="19"/>
							<line fill="none" stroke={theme.mainLightColor} strokeWidth="1.8007" strokeMiterlimit="10" x1="12.208" y1="16.551" x2="12.208" y2="19"/>
						</g>
							<ellipse fill={theme.mainLightColor} stroke={theme.mainLightColor} strokeWidth="1.8007" strokeMiterlimit="10" cx="6.271" cy="5.842" rx="1.24" ry="1.076"/>
							<ellipse fill={theme.mainLightColor} stroke={theme.mainLightColor} strokeWidth="1.8007" strokeMiterlimit="10" cx="6.271" cy="11.471" rx="1.24" ry="1.076"/>
					</g>
				</symbol>

				<symbol id="Batch" viewBox="0 0 18 20">
    			<title>Batch Assign</title>
					<g>
						<rect x="1" y="1" fill="none" stroke={theme.mainLightColor} strokeWidth="2" strokeMiterlimit="10" width="16" height="18"/>
						<g>
							<rect x="3.288" y="3.858" fill={theme.mainLightColor} width="4.988" height="4.95"/>
							<rect x="9.724" y="3.858" fill={theme.mainLightColor} width="4.988" height="12.284"/>
							<rect x="3.288" y="11.192" fill={theme.mainLightColor} width="4.988" height="4.95"/>
						</g>
					</g>
				</symbol>

				<symbol id="Export" viewBox="0 0 29.4 29.4">
    			<title>Export</title>

					<rect x="0" y="0" fill="#002d41" width="29.4" height="29.4"/>
					<line fill="none" stroke="#ffffff" strokeWidth="1.5" strokeMiterlimit="10" x1="14.68" y1="14.63" x2="26.68" y2="14.63"/>
					<line fill="none" stroke="#ffffff" strokeWidth="1.5" strokeMiterlimit="10" x1="23.68" y1="11.63" x2="26.68" y2="14.63"/>
					<line fill="none" stroke="#ffffff" strokeWidth="1.5" strokeMiterlimit="10" x1="23.68" y1="17.63" x2="26.68" y2="14.63"/>
					<polygon fill="none" stroke="#ffffff" strokeWidth="1.5" strokeMiterlimit="10" points="17.68 5.63 7.68 5.63 7.68 23.63 21.68 23.63 21.68 9.63 17.68 5.63"/>

				</symbol>

				<symbol id="SubMenuIndicator" viewBox="0 0 10 31">
    			<title>SubMenuIndicator</title>
    			
    				<polygon fill={theme.mainColor} points="8.441,15.135 3.554,10.248 3.554,0 0,0 0,30.408 3.554,30.408 3.554,20.023 "/>
					
				</symbol>
			</defs>
    </svg>
  );
}