import React from 'react';
import axios from 'axios';
import { resturl, fileurl, theme } from '../../configurations/general-conf';
import * as restful from '../../api/restful-api';
import TiLogo from '../../resources/logo';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginTitle = styled.h1`
  color: ${theme.mainColor};
  font-size: 30px;
  text-align: center;
  margin: 10px 0 30px 0;
`;

const LoginForm = styled.form`
  text-align: center;
`;

const LoginLabel = styled.label`
  font-family: ${theme.boldFont};
	padding: 3px;
	font-size: 18px;
	margin: 10px;
	display: inline-block;
	width: 100px;
`;

const LoginInput = styled.input`
  border: 1px solid ${theme.mainDeepColor};
  padding: 5px;
  margin: 10px;
`;

const LoginSubmit = styled.input`
  border: 1px solid ${theme.mainDeepColor};
  padding: 5px;
  margin: 10px;  
  width: 240px;
  height: 35px;
  background-color: ${theme.mainDeepColor};
  color: white;
  margin-top: 20px;
  cursor:pointer;

  &:hover {
    background-color: ${theme.mainColor};
    color: white;
  }
`;

const LoginFailedMsg = styled.p`
  margin:0px;
	font-size: 14px;
	text-align: center;
  height: 20px;
  color: ${theme.failureColor};
`;

const LoginSuccessMsg = styled.p`
  margin:0px;
  font-size: 14px;
  text-align: center;
  height: 20px;
  color: ${theme.successColor};
`;

class LoginContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loginMsg: '',
      loginStatus: false,
    }
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    axios.defaults.baseURL = resturl;
		axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  }

  login(e){
    e.preventDefault();
    const username = this.refs.username.value;
    const password = this.refs.password.value;
    const _this = this;
		if(username===''||password==='') {
			this.setState({loginMsg: 'Please enter both your name and password.'});
		} else {
      axios({
        url: '/authentication',
        method:"POST",
        data:{ ___time:0, userName: username, passWord: password },
       })
			.then(function (res) {
				//console.log(res.data.result);
				if(res.data.result===1) {
          console.log(res.data.objs[0]);
					axios.defaults.headers.common['auth_token'] = res.data.objs[0].authToken;
          const companyUuid = typeof res.data.objs[0].company === "object" ?  res.data.objs[0].company.supplierUuid : res.data.objs[0].companyUuid;
          const companyName= 
          typeof res.data.objs[0].company === "object" ? ( 
            res.data.objs[0].company.supplierName ? 
            res.data.objs[0].company.supplierName : 
            res.data.objs[0].company.customerName
          ) : res.data.objs[0].company;
          const userType = res.data.objs[0].boardNum;
          const maxDiscountAmount = res.data.objs[0].deviceUuid;
					const expireMins=res.data.objs[0].expireMins;
					restful.setCookie("auth_token", res.data.objs[0].authToken, expireMins);
					restful.setCookie("userName", username, expireMins);
          restful.setCookie("userType", userType, expireMins);
          restful.setCookie("maxDiscountAmount", maxDiscountAmount, expireMins);
					restful.setCookie("companyUuid", companyUuid, expireMins);
					restful.setCookie("companyName", companyName, expireMins);
					restful.setCookie("resturl", resturl, expireMins);
					restful.setCookie("fileurl", fileurl, expireMins);


					_this.setState({
            loginMsg: 'Logged in successfully.',
            loginStatus: true,
          });
          //_this.props.history.push('/abc');
					setTimeout(function(){window.location.href='/';},500);
				} else {
					_this.setState({loginMsg: 'Credentials entered were incorrect.'});
				}
			})
			.catch(function (error) {
				alert(error);
			});
    }
  }

  render() {
    return (
      <Container>
        <TiLogo />
	      <LoginTitle>Inventory Management System</LoginTitle>
	      <LoginForm>
					<LoginLabel> Username: </LoginLabel>
					<LoginInput type="text" ref="username" placeholder="Username" /><br/>
					<LoginLabel> Password: </LoginLabel>
					<LoginInput type="Password" ref="password" placeholder="Password" /><br/>
					<LoginSubmit type="submit" value="Login" onClick={this.login} />
				</LoginForm>
        {
          this.state.loginStatus ?
          <LoginSuccessMsg>{this.state.loginMsg}</LoginSuccessMsg> :
          <LoginFailedMsg>{this.state.loginMsg}</LoginFailedMsg>
        }
      </Container>
    );
  }
}

export default LoginContainer;