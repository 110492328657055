import React from 'react';
import styled from 'styled-components';
import { Route } from 'react-router-dom';
import DashboardLayout from './dashboard-layout';
import { conf } from '../../configurations/home-dashboard-conf';
import ListContainer from '../containers/list-container';
import CreateUpdateContainer from '../containers/create-update-container';
import CustomActionContainer from '../containers/custom-action-container';

const MainContainer = styled.main`
  width: 86.0312%;
  height: calc(100% - 94px);
  float: left;
  padding: 0px 2.5% 0px;
  position: absolute;
  right: 0px;
  top: 94px;
  overflow: auto;
`;

export default function(props){
  //console.log(props.menus);
  return (
    <MainContainer>
      <Route exact path="/" render={(props) => <DashboardLayout {...props} conf={conf} />} />
      {props.menus.map( menu => {
        return <Route exact key={menu.menuId} path={menu.menuLink} render={(props) => <DashboardLayout {...props} conf={menu.conf} />} />;
      })}
      {props.menus.map( menu => {
        return menu.subMenus.map(subMenu => {
          return (
            <React.Fragment key={subMenu.menuId}>
              <Route exact key={'list' + subMenu.menuId} path={subMenu.menuLink} render={(props) => <ListContainer {...props} conf={subMenu.conf} />} />
              <Route exact key={'newItem' + subMenu.menuId} path={subMenu.menuLink + '/new'} render={(props) => <CreateUpdateContainer {...props} conf={subMenu.conf} />} />
              <Route exact key={'editItem' + subMenu.menuId} path={subMenu.menuLink + '/edit/:listItemId'} render={(props) => <CreateUpdateContainer {...props} conf={subMenu.conf} />} />
              { subMenu.conf && subMenu.conf.customAction ? 
                <Route exact key={'custom' + subMenu.menuId} path={subMenu.menuLink + subMenu.conf.customAction.route + '/:listItemId'} render={(props) => <CustomActionContainer {...props} conf={subMenu.conf.customAction} />} />
              : null }
            </React.Fragment>
          );
        });
      })}
    </MainContainer>
  );
}