/* AST internet server */
export const resturl='https://ims.asttimbers.com.au/tistock/rest/v1';
export const fileurl='https://ims.asttimbers.com.au/tistockfile/stock';

/* AST OOS Sandbox server */
// export const resturl='https://sandbox.asttimbers.com.au/tistock/rest/v1';
// export const fileurl='https://sandbox.asttimbers.com.au/tistockfile/stock';

// Trendin Server
// export const resturl = 'http://www.trendin.com.au/tistock/rest/v1';
// export const fileurl = 'http://www.trendin.com.au/tistockfile/stock';

//Leon Local REST test
// export const resturl = 'http://localhost:8081/tistock/rest/v1';
// export const fileurl = 'http://localhost/tistockfile/stock';

//Jeff Local REST test
// export const resturl = 'http://192.168.1.200:8080/tistock/rest/v1';
// export const fileurl = 'http://192.168.1.200/tistockfile/stock';

/* Jerry Local REST server */
// export const resturl = "http://localhost:8080/tistock/rest/v1/";
// export const fileurl = "http://localhost:8080/tistockfile/stock";

//OOS Dev Server
// export const resturl = 'https://oos.asttimbers.com.au/tistock/rest/v1';
// export const fileurl = 'https://oos.asttimbers.com.au/tistockfile/stock';

export const theme = {
  mainColor: '#546223',
  mainDeepColor: '#2c3312',
  mainLightColor: '#d1f556',
  mainLighterColor: '#9bb53f',
  mainDarkColor: '#0b0d04',
  mainDarkColorHover: '#29300f',
  mainBgColor: '#f0f1f2',
  mainBgDeepColor: '#dcddde',
  mainBgDeeperColor: '#d3d3d3',
  mainBgSeparatorColor: '#e6e7e8',
  mainBrightestColor: '#ffffff',
  mainMenuHoverColor: '#b4b4b4',
  mainEditColor: '#0096e1',

  successColor: '#6fbe44',
  failureColor: '#dc502d',
  failureLightColor: '#ff5c33',
  warningColor: '#fed52d',

  boldFont: 'Univers-LT-Bold',
  midFont: 'Univers-LT-Roman',
  lightFont: 'Univers-LT-Light',
};

export const adminCompanyName = 'Australian Select Timbers';
export const newBarcodeUsers = ['Leon Y'];
export const exportUsers = ['Alex Gong', 'Joanne W', 'Leon Y', 'Deanna M', 'Jessica E', 'Emily B', 'Freya F', 'Melanie S', 'Eileen T', 'Beville De Bruin', 'Brent Hudson', 'Margaret C', 'Na Z', 'Faith S', 'Justin B', 'Jordan Alicandro', 'Anthony Zandona', 
                            'Shane W', 'James Dawkins', 'James Kennan', 'Leon Bray', 'Phillip Kirkby', 'David Higgins', 'Robert Vos', 'Tania Taylor', 'Emma Schouten', 'Anthony Z', 'James D', 'Wilfred Q'];
export const markInvoicedUsers = ['Alex Gong', 'Joanne W', 'Leon Y', 'Melanie S', 'Freya F', 'Na Z'];
export const resetOrderUsers = ['Justin B', 'Leon Y', 'Cesar M', 'Faith S'];
export const removeCreateButtonUsers = ['Justin B','Dean K','Cesar M'];