import React from 'react';
import DatePicker from 'react-datepicker';
import '../../resources/react-datepicker.css';
import styled from 'styled-components';
import { theme } from '../../configurations/general-conf';

const DatePickerWrapper = styled.div`
  & .react-datepicker-wrapper, .react-datepicker__input-container {
    width: 100%;
  }

  & input {
    font-size: 14px;
    width: 100%;
    height: 38px;
    box-sizing: border-box;
    border: 1px solid ${theme.mainBgColor};
    padding: 0 0 0 10px;
  }

  & .react-datepicker__close-icon::after {
    background-color: ${theme.mainColor};
    border-radius: 0;
  }

  & .react-datepicker {
    border-radius: 0;
  }

  & .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range {
    background-color: ${theme.mainColor};
    border-radius: 0;
  }

  & .react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover {
    background-color: ${theme.mainLighterColor};
    border-radius: 0;
  }

  & .react-datepicker__day:hover, .react-datepicker__month-text:hover {
    border-radius: 0;
  }
`;

export default class extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: null
    }
  }

  handleChange = (date) => {
    this.setState({ value: date })
  }

  render () {
    const conf = this.props.conf ? this.props.conf : {};
		return (
      <DatePickerWrapper>
        <DatePicker
          placeholderText={conf.placeholder ? conf.placeholder : 'Select Date'}
          dateFormat={conf.dateFormat ? conf.dateFormat : "dd/MM/yyyy"}
          selected={this.state.value}
          onChange={this.handleChange}
          isClearable={conf.isClearable ? conf.isClearable : true}
        />
      </DatePickerWrapper>
		);
	}
}